import React,{useRef,useState,useEffect} from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import ReactPlayer from "react-player";
import PlayerControls from "./PlayerControls";
import screenfull from "screenfull";
import { useUserAuth } from "../../context/UserAuthContext";
import './Player.css';
const playback = process.env.REACT_APP_IMAGE_CDN;
var count = 0;

function ReactPlay() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const {player } = useUserAuth()
  const navigate = useNavigate()
  let {title} = useParams()
  const {checkvalidity }= useUserAuth()
  const search = new URLSearchParams(window.location.search)
  const userID = localStorage.getItem('id')
  const userId = search.get("userId");
  const id = search.get("id");
  const contentId = search.get("contentId");
  const seasonId = search.get("seasonId");
  const episodeId = search.get("episodeId");
  const path = search.get('path')
  const type = search.get("type");
  const playerRef = useRef()
  const playerContainerRef= useRef()
  const controlRef = useRef()
  const [hlsUrl,sethlsUrl]=useState('')
  const [trackLang, setTrackLang] = useState('');
  const token = localStorage.getItem('access-token')
  const [flag,setflag]=useState(false)
  const [url,seturl]=useState('')
  const [query,setquery]=useState('')
  useEffect(()=>{
    if(type &&  type === 'episode_playback'){
      Run()
      handleepisode()
    }else if(type && type === 'upcoming_trailer'){
      sethlsUrl(`${playback}${id}/trailer.m3u8`)
      setflag(true)
      setTimeout(()=>{
        toggleFullScreen()
      },[100])
    }
    else{
      setTimeout(()=>{
        toggleFullScreen()
      },[100])
      sethlsUrl(id)
    }

    var Hls = playerRef.current.getInternalPlayer('hls');
    var video = playerRef.current;
  },[])
  const Run =async()=>{
    const Check = await checkvalidity()
    if(Check.code === 1002){
      localStorage.clear()
      navigate('/login')
    }
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const [para ,setpara ]= useState([{playerotp:''}])
  const drmapiKey ="dan8fKLn1nd2nnRap2pn5w==" ;
  const accesstoken = token ;
  const versioncode = "1.0" ;

  const handleepisode=()=>{
    if(!userId){
        navigate('/login')
    }
    const data = {
        "contentId":contentId, 
        "seasonId":seasonId,
        "episodeId":episodeId, 
        "path":path,
        "keyversion":"9.0",//8.0 //7.0 
        "contenttype":'cc',
        "countrycode":"in", 
        "platform":"android",
        "ishevcSupported":false, 
        "ishevcHardwareSupported":false,
        "client":"web", 
        "adaptive":true,
        "osversion":"22", 
        "streaming_type":"HLS", 
        "userId":userId, 
    }
    player(data)
    .then(res=>{
      const obj = JSON.parse(res)
      if(obj.code === 1002){
        navigate('/login')
      }
      sethlsUrl(obj.url)
      const data = obj.url.split('?')
      //sethlsUrl('https://d148xld5ybft60.cloudfront.net/testaes1/playlist.m3u8?'+data[1])
      //sethlsUrl('https://d148xld5ybft60.cloudfront.net/testaes3/playlist.m3u8?'+data[1])
      seturl(data[0])
      setquery(data[1] ) //query params
      setTimeout(()=>{
        toggleFullScreen()
      },[100])
      if(obj.error === true){
        navigate('/subscriptions/plans')
        //console.log('move to subscription page ')
      }    
    })

}
  const [state,setstate] = useState({
    playing:false,
    muted:true,
    playbackRate:1.0,
    played:0,
    seeking:false,
    pip:false
  })


  const Fullscreen=()=>{
    if (playerRef.current.requestFullscreen) {
        playerRef.current.requestFullscreen();
      } else if (playerRef.current.webkitRequestFullscreen) { /* Safari */
        playerRef.current.webkitRequestFullscreen();
      } else if (playerRef.current.msRequestFullscreen) { /* IE11 */
        playerRef.current.msRequestFullscreen();
      }
}


  const [buff,setBuff]=useState(false)
  const fullscreen=()=>{
    
  }
  const handleplaypause=()=>{
    setstate({...state, playing: !state.playing})
    const play = playerRef.current.getInternalPlayer()
  }
  const handleforward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
  }
  const handlebackward=()=>{
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
  }
  const handlemute=()=>{
    setstate({...state, muted:!state.muted})
  }

  const handleplaybackratechange=(rate)=>{
    setstate({...state, playbackRate : rate })
  }

  const toggleFullScreen=()=>{
    screenfull.toggle(playerContainerRef.current)
  }
  const handleBuffer=()=>{
    setBuff(true)
  }
    const handleBufferEnd=()=>{
    setBuff(false)
  }


  const handleProgress=(changeState)=>{
    if(count > 3){
      controlRef.current.style.visibility="hidden"
      count = 0
    }
    if(controlRef.current.style.visibility === 'visible'){
      count+=1
    }
    if(!state.seeking){
      setstate({...state, ...changeState})
    }

  }

  const handleSeekChange=(e,newValue)=>{
    setstate({...state,played:parseFloat(newValue/100)})
  }
  const handleSeekMouseDown=(e)=>{
    setstate({...state,seeking:true})
  }
  const handleSeekMouseUp=(e,newValue)=>{
    setstate({...state,seeking:false})
    playerRef.current.seekTo(newValue/100)
  }

    const format=(seconds)=>{
      if(isNaN(seconds)){
        return '00:00'
      }
      const date = new Date(seconds*1000)
      const hh = date.getUTCHours()
      const mm = date.getUTCMinutes()
      const ss = date.getUTCSeconds().toString().padStart(2,"0")
      if(hh){
        return `${hh}:${mm.toString().padStart(2,"0")}:${ss}`
      }
      return `${mm}:${ss}`
    }
  const currentTime = playerRef.current ? 
  playerRef.current.getCurrentTime() : '00:00'

  const duration = playerRef.current ? 
  playerRef.current.getDuration() : '00:00'

  const elapsedTime = format(currentTime)
  const totalDuration = format(duration)

  const handleMouseMove=()=>{
    controlRef.current.style.visibility="visible";
    count = 0
  }
  const handleVolumeChange=(e, newValue)=>{
    setstate({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });

  }
  const handleVolumeSeekDown=(e, newValue)=>{
    setstate({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  }

  const handleQuality=(quality , level)=>{
    //console.log(level)
    setstate({...state, playing: false})
    var hls = playerRef.current.getInternalPlayer('hls');
    if(quality === '-1'){
      hls.loadLevel = quality
      hls.subtitleTrack = level
    }else{
      hls.currentLevel = quality
      hls.subtitleTrack = level
    }
    //console.log(alllevel)
    //setstate({...state, playing: !state.playing})
    setstate({...state, playing: true})
  }

  const onTrackChange=(e)=>{
    //console.log(e)
    setTrackLang(e)
  }
  const handlePip=()=>{
    setstate({...state, pip:!pip})
  }

  const{playing,muted,playbackRate,played,seeking,pip}=state  
//url + '?playerotp='+playerotp+ '&userid='+userid+'&free='+free+'&subscribed='+subscribed+'&countrycode='+countrycode+'&platform='+platform+'&keyversion='+keyversion+'&Policy='+policy}
  //https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8
  //http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4
  return (
    <>
    
      <div className="spacer"></div>
        <div className="playerWrapper" 
        ref={playerContainerRef}
        onMouseMove={handleMouseMove}
        >
          <ReactPlayer
            width={"100%"}
            height="100%"
            url={hlsUrl}
            pip={pip}
            muted={muted}
            playing={playing}
            ref={playerRef}
            playbackRate={playbackRate}
            onProgress={handleProgress}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
            config={{
              file: {
                hlsOptions: {
                  forceHLS: true,
                  debug: false,
                  xhrSetup: function(xhr, url) {
                      xhr.setRequestHeader('drmapiKey',"dan8fKLn1nd2nnRap2pn5w==")
                      xhr.setRequestHeader('accesstoken',token)
                      xhr.setRequestHeader('versioncode',"1.0")
                      if(!url.includes('playlist.m3u8')){
                        xhr.open('GET', url +'?' + query )
                        xhr.setRequestHeader('drmapiKey',"dan8fKLn1nd2nnRap2pn5w==")
                        xhr.setRequestHeader('accesstoken',token)
                        xhr.setRequestHeader('versioncode',"1.0")
                      }
                      if(url.includes('rabbitmediakeyserver')){
                        xhr.open('GET', url +'?id='+userID )
                        xhr.setRequestHeader('drmapiKey',"dan8fKLn1nd2nnRap2pn5w==")
                        xhr.setRequestHeader('accesstoken',token)
                        xhr.setRequestHeader('versioncode',"1.0")
                      }
                  },
                },
              },
            }}
          />  
        <PlayerControls 
        flag={flag}
        playerRef={playerRef}
        contentId={contentId}
        ref={controlRef}
        onPlayPause={handleplaypause}
        playing={playing} 
        onforward={handleforward}
        onbackward={handlebackward}
        muted={muted}
        onmute={handlemute}
        playbackRate={playbackRate}
        onplaybackratechange={handleplaybackratechange}
        onToggleFullScreen={toggleFullScreen}
        played={played}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        onVolumeChange={handleVolumeChange}
        onVolumeSeekDown={handleVolumeSeekDown}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        Buffer={buff}
        onBuffer={handleBuffer}
        onBufferEnd={handleBufferEnd}
        title={title}
        onQuality={handleQuality}
        onTrack={onTrackChange}
        onPip={handlePip}
        //onDetail={handlePlayer}
        
        />
          
        </div>
      
    </>
  );
}

export default ReactPlay;