import React from 'react'

const Refundpolicy = () => {
  return (
    <>
    <section className="policy">
    <div className="container">
        <div className="policy_area">
            <div className="policy_area_col">
                <div className="refundpolicy_heading">
                <div class="policy_heading">
                    <h2>Refund <span>Policy</span></h2>
                </div>
                    <p align="left">
                        policy applies to Rabbit Site/App Platforms including without limitation
                        <a href="https://www.rabbitmovies.app"> www.rabbitmovies.app</a> other related
                        Sites/s or App/s,
                        mobile
                        applications and other online features each a “Site/s or App/s”.We have provided extensive
                        information
                        for you to view the packages before choosing to subscribe to us. If you have any questions or
                        reservations, please contact us at
                        <a href="mailto:rabbitmoviespvtltd@gmail.com"> rabbitmoviespvtltd@gmail.com</a> prior
                        to
                        subscribing to our
                        services.
                        We,being the service providers for contents available through website or APP where you are
                        expected to
                        view packages of your choice after being paid for subscription,unfortunately,all fees to Rabbit
                        for
                        such services are non refundable.
                        <br/>
                        <br/>
                        In case because of any technical glitch at the time of online transaction, the transaction does
                        not occur
                        the amount in process of transfer by default goes back into your bank account,automatically
                        through
                        Payment Gateway.
                    </p>
                    <div class="policy_heading">
                    <h2>Chargebreaks <span></span></h2>
                </div>
                    <p >
                        If we receive a chargeback or payment dispute from a credit card company or bank, your service
                        and/or
                        subscription will be suspended without notice. Applicable chargeback fees will be issued to
                        recovers fees
                        passed on to us by the credit company, plus any outstanding balances accured as a result of the
                        chargeback(s) must be paid in full before service is restored. Instead of issuing a chargeback,
                        contact us
                        to address any billing issues. Requesting a chargeback or opening any sort of dispute for a
                        valid charge
                        from us is fraud, and is never an appropriate or legal means of obtaining a refund.
                    </p>
                    <div className="policy_area_col">
                    <p className="text-br/and"><strong>Please read and make sure you fully understand our refund policy
                            prior to making a payment.</strong></p>
                    <p className="text-br/and"><i>If you need more information you can contact us at <a href="mailto:rabbitmoviespvtltd@gmail.com">
                         rabbitmoviespvtltd@gmail.com</a></i></p>
                                </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default Refundpolicy