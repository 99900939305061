import React,{useState,useEffect} from 'react'
import Footer from '../Footer'
import newsample from "../../assets/gif/Rabbit Promotion 1500x750 Poster.gif";
import sample from "../../assets/gif/mobilegifnew.gif";
import sam1 from '../../assets/gif/poster1.jpg';
import './add.css';
import right from '../../assets/image/subscribe_info_icon.png';
import banner4 from '../../assets/gif/banner5.jpeg';
import review1 from '../../assets/gif/rating1.png';
import review2 from '../../assets/gif/rating2.png';
import review3 from '../../assets/gif/rating3.png';
import review4 from '../../assets/gif/rating4.png';
import review5 from '../../assets/gif/rating5.png';
import review6 from '../../assets/gif/rating6.png';
import review7 from '../../assets/gif/rating7.png';
import { useUserAuth } from '../../context/UserAuthContext';
const Adds = () => {
    const [flag,setflag]=useState(false)
    const { allSubscriptions} = useUserAuth();
    useEffect(()=>{
      console.log('landing')
        if(window){
          if(window.innerWidth > 600){
            setflag(true)  
          }
        }
      },[])
  return (
    <>
    <div className='landing'>
    <div className="video-wrapper">
    {!flag ?<img src={sample} />:
    <img src={newsample} />}
    <div className='mid'>
      <h2><a href="https://play.google.com/store/apps/details?id=com.rabbit.android.pro.release&hl=en_IN&gl=US" target='_blank'>Download Rabbit App</a></h2>
    </div>
    </div>
    <div className='container adds'>
    <div className='col-md-6'>
        <img src={sam1}/>
      </div>
      <div className='col-md-6'>
      <h1>Why to Watch on Rabbit App?</h1>
      <ul>
        <li>Unlimited HD streaming and downloading 24x7.</li>
        <li>Flip through trailers to help choose what to watch first.</li>
        <li>Personalized content according to your geographical location.</li>
        <li>Download to watch offline on android and IOS </li>
        <li>Exclusive multi regional content</li>
        <li>Personalize your membership plan to suit your need.</li>
        <li>Stream videos to TV with 4K quality</li>
        <li>Access content anywhere in the world.</li>
        <li>Download videos for offline viewing-on-the-go.</li>
        <li>Cast to TV</li>
      </ul>
      </div>
    </div>
    <div className='container adds'>
    <div className='col-md-6'>
      <h1>Rabbit Movies</h1>
      <p>Rabbit is a video on demand streaming service that offers a wide variety of genres from drama, horror, suspense, thriller to comedy & erotic and 
        beyond. Binge watch from our collection of web series, movies, Rabbit Originals and more in your regional language.</p>
      </div>
        <div className='col-md-6'>
        <img src={banner4}/>
      </div>
    </div>
    <h2><img src={right}/> Checkout our most watched trailer videos.</h2>
    <div className='container adds scrollable'>
    <iframe width="360" height="240" src="https://www.youtube.com/embed/8JvnBmutM2Y?si=_OLLqr8qUaA80ukc&amp;controls=0" title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <iframe width="360" height="240" src="https://www.youtube.com/embed/8e1PUbgdxdk?si=i7d2Wp20UxfxsO3B&amp;controls=0" title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <iframe width="360" height="240" src="https://www.youtube.com/embed/CE0gyXMagwA?si=MsOHiXToJP5bgnqq&amp;controls=0" title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <iframe width="360" height="240" src="https://www.youtube.com/embed/u6EYm8LjR08?si=h_OQtE8zcG-NIHJ1&amp;controls=0" title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <h2><img src={right}/>Get Pocket friendly subscriptions plans! subscribe now buy clicking on buy now button</h2>
    <div class="membership subs addperpose">
                <ul>
                    {allSubscriptions.map(x=>{
                        if(x.subscription.price.IN.value / 100 < 400 ){
                            return (
                                <li>
                                    <div class="membership_col">
                                        <h4> <span><img src="assets/img/price_symbol.png" alt=""/></span> Rs. {x.subscription.price.IN.value / 100 }</h4>
                                        {x.subscription.description.map((z,index)=>{
                                            return(
                                                <p key={index}>{z}</p>
                                            )
                                        })}
                                        <h5>{x.subscription.period} <br/><span>Days</span></h5>
                                        
                                        <a href="https://play.google.com/store/apps/details?id=com.rabbit.android.pro.release&hl=en_IN&gl=US" target='_blank'><button className='btn_buy'> Buy Now</button> </a>
                                    </div>
                                </li>
                            )
                        }
                           
                    })}
                </ul>
    </div>
    <h2><img src={right}/>Customers Reviews on Google-Play store.</h2>
    <div className='container adds scrollable  review'>
        <img src={review1}/>
        <img src={review2}/>
        <img src={review3}/>
        <img src={review4}/>
        <img src={review5}/>
        <img src={review6}/>
        <img src={review7}/>
    </div>
   
    </div>
    
  
    <Footer/>
    </>
  )
}

export default Adds