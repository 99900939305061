import React, {useEffect, useState,useRef}  from 'react'
import facebook from "../assets/image/facebook_icon.png";
import google from "../assets/image/google_icon.png";
import whatsapp from "../assets/image/whatsapp.png";
import user from "../assets/image/input_field_user.png";
import password from "../assets/image/input_field_password.png";
import logo from '../assets/image/logo.png';
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input"
import { useNavigate, useParams } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Loginone = () => {
    let navigate = useNavigate()
    const { handleFacebookSignIn, loginwithemail,verify,googleSignIn} = useUserAuth();
    const [passwordinput,setpassword] = useState('');
    const [emailinput,setemailinput]=useState('')
    const [permission,setpermission]=useState(['rabbit']);
    const [number , setnumber]=useState('')
    const [usercheck, setusercheck]= useState(true)
    const [msg,setmsg]=useState('')
    const [Login,setLogin]=useState(true)
    const [rst,setRst]=useState(true)
    const [spin,setspin]=useState(false)
    const [hide,sethide]=useState(false)
    const [flag,setflag]=useState(false)
    var pattern = new RegExp(/^[0-9\b]+$/);
    const country  = localStorage.getItem('CC')
    const recap = useRef()
    const search = new URLSearchParams(window.location.search)
    const message = search.get("message");
    const callback = search.get("callback");
    const logintoken = search.get("token");
    const socketId = search.get("socketId");
    useEffect(()=>{
        if(country && country !== 'IN'){
            sethide(true)
        }   
        //otp less code
        const script = document.createElement("script");
        script.src = "https://otpless.com/auth.js";
        document.body.appendChild(script);
        const otplessInit = Reflect.get(window, 'otplessInit');
        otplessInit?.();
        Reflect.set(window, 'otpless', otpless);
        
        return () =>{
        document.body.removeChild(script);
        }
    },[])
    /*otpless signin*/
    const otpless = (otplessUser) => {
        // your_code_here
        const token = otplessUser.token;
        alert(token)
        if(token){
            const cred = {            
                token:token
            }
            //login_with_whatsapp(cred)
        }
    }
    const handleuser =(e)=>{
        let email_val = e.target.value
        if(email_val !== ''){
            setemailinput(email_val)
            //setem('')
            setLogin(true)
            setmsg("")
        }
        pattern.test(e.target.value) ?  logic(e) : setusercheck(true);
    }

     const logic=(e)=>{
        setusercheck(false)
        setRst(false) 
        setnumber("+91" +e.target.value)
        setLogin(false)
      }

     const handlepassword=(e)=>{
        setpassword(e.target.value)
        setmsg("")
     }
     
    //validating its email sign in or mobile sign in using input value 
    const login= async (e)=>{
        e.preventDefault();
        setspin(!spin)
        var data={}
            if(!Login){
                data= { 
                   mobile:number,
                   password:passwordinput, 
                   permission:permission
                } 
            }else{
                data= { 
                    email:emailinput,
                    password:passwordinput, 
                    permission:permission
                } 
                const resp= await loginwithemail(data,callback,logintoken)
                if(resp && resp.data){
                if(resp.data._id && callback === 'weboslogin'){
                    navigate(`/my-profile/${resp.data._id}&&token=${logintoken}callback=${callback}`)
                }else if(resp.data._id){
                    navigate(`/my-profile/data?id=${resp.data._id}&&token=${resp.data.accessToken}`)
                }
                setmsg(resp.message)
                setspin(false)
                }
            }
       
    }

    const handler=async(e)=>{
        setflag(true)
        if(e === null){
            setflag(true)
            setmsg('Recaptcha verfication failed !!')
        }else{
          //  const result = await verify(e)
            //if(result.data.data.status){
              // setflag(true)
            //}
        }
    }

    const googleSignInHandler=async(e)=>{
        e.preventDefault();
        const res = await googleSignIn();
        if(res.code === 400 && callback === 'weboslogin'){
            navigate(`/my-profile/data?id=${res.data.id}&&token=${logintoken}&&socketId=${socketId}&&accesstoken=${res.data.accessToken}&&callback=${callback}`)
        }else{
            navigate(`/my-profile/data?id=${res.data.id}&&accesstoken=${res.data.accessToken}`)
        }
    }

  return (
    <>
    <section className="login">
    <div className="logincontent left">
        <div className="content_area">
            <div className="content_inner_area">
                <a href="/registration" className="login_logo"><img src={logo} alt=""/></a>
                <ul>
                    <li>Watch any first 4 videos absolutely free</li>
                    <li>Unlimited HD streaming for prime users</li>
                    <li>Download and watch offline on android  IOS</li>
                </ul>
               
                   {!hide ? 
                   <>
                    <h3>Dont Have An Account?</h3>
                    <div className="flip_btn_area">
                    <a href="/register" className="flip_btn">REGISTER NOW</a>
                    </div>
                    </> 
                    :''}
                
            </div>
        </div>
    </div>
    <div className="login_form right">
        <div className="login_form_area">
            <div className="login_inner_area">
                <a href="/" className="back_home"><span>Back To Home</span></a>
              
                <div className="content_heading m-2"><h2>Sign<span> In</span></h2></div>
                {!hide ?  
                <form>
                        <div className="input_field_area">
                        <h5  className="input mes mb-4"style={{"color":"red","marginBottom":"15px"}}>{msg}</h5>
                        <p className="p-2" style={{'color':'#fff'}}><i className="fa fa-arrow-right"></i> <span className='p-2'>Sign In using Email</span></p>
                        {usercheck?
                        <div className="input_field_col">
                            <span><img src={user} alt=""/></span>
                            <input type="text" onChange={handleuser} placeholder="Enter Your Email Or Mobile  Number" required  />
                        </div>:
                        
                        <div className="input_field_col mb-4">
                        <PhoneInput
                        international
                        style={{  'padding': '5px','border':'1px solid #fff'}}
                            defaultCountry="IN"
                            countryCallingCodeEditable={false}
                            value={number}
                            onChange={setnumber}
                            placeholder="Enter Phone Number"
                           required 
                        />

                        </div>}
                        
                        <div className="input_field_col ">    
                            <span><img src={password} alt=""/></span>
                            <input type="password" className='form-control' onChange={handlepassword} placeholder="Enter Password *" required/>
                        </div> 
                        
                        <ReCAPTCHA sitekey={SITE_KEY} onChange={handler} />

                        <div className="input_field_col">
                        <a href="/forgetpassword">Forget Password?</a>
                        {flag ? 
                        <button className='login mt-3' onClick={(e)=>login(e)}> {!spin ?  'LOGIN':<div className='spin'/> }</button> 
                        :
                        <button className='login mt-3'  disabled onClick={(e)=>login(e)}> {!spin ?  'LOGIN':<div className='spin'/> }</button> 
                        }
                        </div>
                        </div>
                </form>:
                ''} 
                <ul className="social_connect">
                <li><div id="otpless" custom="true"><span><img src={whatsapp} alt=""/></span> Whatsapp</div></li>
                <li><a href="/" onClick={handleFacebookSignIn}><span><img src={facebook} alt=""/></span> Facebook</a></li>
                <li><a href="/" onClick={googleSignInHandler}><span><img src={google} alt=""/></span> Google</a></li>
                </ul>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Loginone