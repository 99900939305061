import { auth, analytics}  from "../firebase";
import { logEvent } from "firebase/analytics";
import {singularSdk, SingularConfig} from "singular-sdk";
import ReactPixel from 'react-facebook-pixel';
const sdkKey = process.env.REACT_APP_SDKKEY;
const sdkSecret = process.env.REACT_APP_SDKSECRET;
const productId = process.env.REACT_APP_PRODUCTID;
const config = new SingularConfig(sdkKey, sdkSecret, productId);
//react facebook fixel code
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('763280268641939', options);
singularSdk.init(config);
const upcoming_trailer =(title)=>{
    logEvent(analytics,'upcoming_trailer',{client:'web' , name:title})
}
const deep_link=(title)=>{
    logEvent(analytics,'deep_link',{client:'web', name:title})
}
const click_poster=(title)=>{
    logEvent(analytics,'click_poster',{client:'web', name:title})
}
const checkout_paytm=(price,currency)=>{
    logEvent(analytics,'checkout_paytm',{client:'web', price:price , currency:currency})
    ReactPixel.trackCustom('checkout_paytm',{client:'web', price:price , currency:currency})
}
const checkout_stripe=(price,currency)=>{
    logEvent(analytics,'checkout_stripe',{client:'web', price:price , currency:currency})
    ReactPixel.trackCustom('checkout_stripe',{client:'web', price:price , currency:currency})
}
const begin_checkout=(price,currency)=>{
    logEvent(analytics,'begin_checkout',{client:'web', price:price , currency:currency})
}
const purchase=(orderId,txnid)=>{
    logEvent(analytics,'purchase',{client:'web', orderId:orderId,transaction_id:txnid})
    ReactPixel.trackCustom('purchase',{client:'web', orderId:orderId,transaction_id:txnid})
}
const view_item=(title)=>{
    logEvent(analytics,'view_item',{client:'web', title:title})
}
const login=(mode)=>{
    logEvent(analytics,'login',{client:'web', type:mode})
    singularSdk.event('login_web',{name:mode})
    ReactPixel.trackCustom('login_web',{name:mode})
}
const sign_up=(mode)=>{
    logEvent(analytics,'sign_up',{client:'web', type:mode})
    ReactPixel.trackCustom('sign_up',{client:'web', type:mode})
}
const watch_trailer=()=>{
    logEvent(analytics,'watch_trailer',{client:'web'})
    ReactPixel.trackCustom('watch_trailer',{client:'web'})
}
const upgrade_package=()=>{
    logEvent(analytics, 'upgrade_package' ,{client:'web'})
}
const add_to_cart=(name)=>{
    logEvent(analytics, 'add_to_cart' ,{client:'web',name:name})
}
const revenue=(data)=>{
    singularSdk.revenue("rabbitRevenue",data.currency, data.amount);
    ReactPixel.trackCustom("rabbitRevenue",data.currency, data.amount);
  }
export {upcoming_trailer,deep_link,checkout_paytm,checkout_stripe,
    begin_checkout,purchase,view_item,click_poster,login,sign_up,
    watch_trailer,upgrade_package,add_to_cart,revenue}