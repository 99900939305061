import React, {useState}  from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import logo from '../../assets/image/logo.png';
import password from "../../assets/image/input_field_password.png";
import user from "../../assets/image/input_field_user.png";

const Changepassword = () => {
    let {userId} = useParams();
    const navigate = useNavigate()
    const [newpassword,setnewpassword]= useState('');
    const [cnew,setcnew]= useState('');
    const [current,setcurrent]= useState('');
    const [alert,setalert]=useState('')
    const [passwordalert,setpasswordalert]=useState('')
    const [flag ,setflag]=useState(false)
    const { forgetpass , handleFacebookSignIn,handleGoogleSignIn,Reset,ChangePassword} = useUserAuth();
    var regularExpression = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/);
    const reset=async(e)=>{
        e.preventDefault();
        setflag(true)
        if(!regularExpression.test(newpassword)){
            setpasswordalert('Password must contain minimum 1 unique special character and 1 numeric value.')
            setalert('Password must contain minimum 1 unique special character and 1 numeric value.')
        }else{
            setpasswordalert('')
            if(newpassword === cnew){
                const data = {oldPassword:current,newPassword:newpassword,confirmPassword:cnew, userId:userId}
                const result = await ChangePassword(data)
                console.log(result)
                setalert(result.message)
                setTimeout(()=>{
                if(result.error === false){
                    //navigate('/')
                }
                },[3000])
            }else{
                setalert('password not matching')
            }
        }
        
       
    }
  return (
    <>
    <section className="login">
    <div className="logincontent left">
        <div className="content_area">
            <div className="content_inner_area">
                <a href="/registration" className="login_logo"><img src={logo} alt=""/></a>
                <ul>
                    <li>Watch any first 4 videos absolutely free</li>
                    <li>Unlimited HD streaming for prime users</li>
                    <li>Download and watch offline on android  IOS</li>
                </ul>
                <h3>Dont Have An Account?</h3>
                <div className="flip_btn_area">
                    <a href="/register" className="flip_btn">REGISTER NOW</a>
                </div>
            </div>
        </div>
    </div>
    <div className="login_form right">
        <div className="login_form_area">
            <div className="login_inner_area">
                <a href={"/my-profile/"+ userId} className="back_home"><span>Back</span></a>
                <div className="content_heading"><h2>Change<span> Password</span></h2></div>
                <form onSubmit={reset}>
                {flag ? <p className="alert alert-primary"> {alert}</p>:''}
                <div className="input_field_area">
                <div className="input_field_col">
                        <span><img src={user} alt=""/></span>
                        <input required type="password" onChange={(e)=>setcurrent(e.target.value)} placeholder="Current Password" />
                    </div>
                    <span className='small'>{passwordalert}</span>
                    <div className="input_field_col">
                        <span><img src={password} alt=""/></span>
                        <input required type="password" onChange={(e)=>setnewpassword(e.target.value)} placeholder="New Password" />
                    </div>
                    <div className="input_field_col">
                        <span><img src={password} alt=""/></span>
                        <input required type="password" onChange={(e)=>setcnew(e.target.value)} placeholder="Confirm Password" />
                        <button className='login' type="submit">submit</button>
                    </div>
                </div>
                </form>
                
                
            </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Changepassword