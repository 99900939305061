import React,{useEffect,useState,useRef} from 'react';
import { useUserAuth } from '../context/UserAuthContext'
const static_url = process.env.REACT_APP_IMAGE_CDN;
const Upcomingcontents = () => {
const {allUpcomings } = useUserAuth()
  return (
    <section className="product upcom">
    <div className="container">
        <div className="product_category">
            <div className="product_category_heading">
                <h2>Upcoming Contents</h2>
            </div>
            <div className="product_item_list">
            {
            allUpcomings.map((x,index) => {
                return(
                        <div key={index}>
                        
                                <a  key={index}  href={'/upcoming/content/details/'+`${x.upcoming._id}`} className="product_item">
                                    <div className="product_item_image upcoming">
                                    <img src={static_url+ x.upcoming.landscape_poster_id} alt=""/>
                                        <div className="product_item_image_overlay">
                                            <div className="play_icon"></div>
                                        </div>
                                    </div>
                                    <p>{x.upcoming.title}</p>
                                </a>
                        
                        </div>
                )
            })}
            </div>
         </div>
    </div>
</section>
  )
}

export default Upcomingcontents