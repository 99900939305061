import React, { useState,useEffect } from 'react'
import { useUserAuth } from '../context/UserAuthContext'

const Listpackage = () => {
    const { allSubscriptions,Check_subscription,setCountryCode } = useUserAuth();
    const [countrycode , setCountrycode]=useState('IN')
    
    useEffect(()=>{
        Check_subscription()
        setCountryCode()
        //const cc = localStorage.getItem('CC')
        //console.log(cc)
        //setCountrycode(cc)
    },[])
  return (
    <section class="inner_subcribe subs" style={{'marginTop':'100px'}}>
    <div class="container">
        <div class="inner_subcribe_area">
            <div class="subscribe_heading">
                <h2>Subscribe And Enjoy </h2>
            </div>
            <ul class="subscribe_info">
                <li>Unlimited Access and HD Streaming</li>
                <li>Download to watch offline on android and IOS</li>
                <li>Exclusive multi regional content</li>
            </ul>
            <div class="membership">
                <h2>Choose  a plan for becoming<span> Prime member</span></h2>
                <ul>
                    {allSubscriptions.map(x=>{
                        if(countrycode === 'IN'){
                            return (
                                <li>
                                    <div class="membership_col">
                                        <h4> <span><img src="assets/img/price_symbol.png" alt=""/></span> Rs. {x.subscription.price.IN.value / 100 }</h4>
                                        {x.subscription.description.map((z,index)=>{
                                            return(
                                                <p key={index}>{z}</p>
                                            )
                                        })}
                                        <h5>{x.subscription.period} <br/><span>Days</span></h5>
                                        
                                        <a href={'/subscriptions/payments/modes/active/'+ x.subscription._id}><button className='btn_buy'> Buy Now</button> </a>
                                    </div>
                                </li>
                            )
                        }else if(countrycode === 'US'){
                            return (
                                <li>
                                    <div class="membership_col">
                                        <h4> <span><img src="assets/img/price_symbol.png" alt=""/></span> USD {x.subscription.price.US.value / 100}</h4>
                                        {x.subscription.description.map((z,index)=>{
                                            return(
                                                <p key={index}>{z}</p>
                                            )
                                        })}
                                        <h5>{x.subscription.period} <br/><span>Days</span></h5>
                                    </div>
                                </li>
                            )
                        }else if(countrycode === 'PK'){
                            return (
                                <li>
                                    <div class="membership_col">
                                        <h4> <span><img src="assets/img/price_symbol.png" alt=""/></span> PK {x.subscription.price.US.value / 100}</h4>
                                        {x.subscription.description.map((z,index)=>{
                                            return(
                                                <p key={index}>{z}</p>
                                            )
                                        })}
                                        <h5>{x.subscription.period} <br/><span>Days</span></h5>
                                    </div>
                                </li>
                            )
                        }
                           
                        
                    })}
                   
                    
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default Listpackage