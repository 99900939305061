import React,{useEffect,useState,useRef} from 'react'
import { useParams , useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import prev from '../assets/image/prev-icon.png'
import next from '../assets/image/next-icon.png'
import Play from '../assets/image/play-btn.png'

const static_url = process.env.REACT_APP_IMAGE_CDN;
const Contentdetails = () => {
const navigate = useNavigate()
const [selectedOption, setSelectedOption] = useState(null);
let {contentId} = useParams();
const { allContents } = useUserAuth();
const [persons, setPersons] = useState({seasons:[] , actors:[], tags:[]});
const [options ,setoptions]=useState([]);
const [seasonId,setseasonId]=useState('')
const [season ,setseason]=useState([])
const [child,setchild]=useState({episodes:[]})
const [flag,setflag]=useState(true)
const [date,setdate]=useState('')
const [casts,setcasts]=useState([])
const [description,setdescription]=useState('')

const userId = localStorage.getItem('id')
const scrl = useRef()

useEffect(() => {
    validate(allContents)
},[])
const validate=(res)=>{
    for(var i = 0; i<res.length;i++){
        if(res[i].id === contentId){
            setPersons(res[i].test) 
            setseason(res[i].test.seasons)
            const dat= res[i].test.releaseDate.toLocaleString()
            setdate(dat)
            if(res[i].test.seasons.length <= 0){
                setflag(!flag)
            }else{
                if(res[i].test.seasons[0].episodes.length > 0){
                    setcasts(res[i].test.seasons[0].episodes[0].cast)
                    setdescription(res[i].test.seasons[0].episodes[0].description)
                    setoptions(res[i].test.seasons.map(x => {return  {'value': x._id, 'label': x.name}}))
                    setSelectedOption({value:res[i].test.seasons[0]._id,label:res[i].test.seasons[0].name})
                    seasoncheck(res[i].test.seasons[0]._id,res[i].test.seasons)
                }
            }
           
        }
    }
}

const seasoncheck=(seasonId,season)=>{
    setseasonId(seasonId)
        var i;
        for(i=0 ; i < season.length;i++){
            if(season[i]._id === seasonId){
                setchild(season[i])
                if(season[i].episodes.length === 0){
                    setmsg('No episodes')
                }else{
                    setmsg('')
                }
            }
        }
}
const [msg,setmsg]=useState('No seasons & Episodes')
const handle=(e)=>{
setSelectedOption(e)
seasoncheck(e.value,season)
}
const [hlsUrl,sethlsUrl]=useState('https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8')

const assign=(path,title)=>{
    navigate('/trailer/play/'+title+'/url?contentId='+contentId +'&&id='+ path)
}
async function decryptData(encryptedObject) {
    const keyObject={'key':"21be3b062d58b21f1f9a5570c43638de",
  'iv':"1e01ab7a7b98b9e5d31b6240acc7b758",
  }
    const decryptedObject = await decrypt(encryptedObject, keyObject);
    return decryptedObject;
  }
  async function decrypt(encryptedText, keyObject) {
    const textDecoder = new TextDecoder("utf-8");
    const decryptedText = await crypto.subtle.decrypt({ name: 'AES-CBC', iv:  converthextToByteBuffer(keyObject.iv) }, await genearteKey(keyObject.key), encryptedText);
    return textDecoder.decode(decryptedText);
  }

  async function genearteKey(key){
    return crypto.subtle.importKey("raw",  converthextToByteBuffer(key),"AES-CBC",false,["decrypt"])
  }
  
  function converthextToByteBuffer(hex){
    var typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16)
   }))
     return typedArray.buffer
   }
const decryption = async (sundaram)=>{
    const result= await decryptData(Uint8Array.from(window.atob(sundaram), (c) => c.charCodeAt(0)))  
    return result
}
const handleepisode=async (x)=>{
    const data = await decryption(x.mediaFileUrl)
    if( x.name === '' ){
        navigate('/episode/play/'+ `${persons.title}` +'/url?userId='+userId+'&&'+'contentId='+contentId+'&&seasonId='+seasonId+'&&episodeId='+x._id+'&&type=episode_playback&&path='+data)
    }else{
        navigate('/episode/play/'+ `${x.name}` +'/url?userId='+userId+'&&'+'contentId='+contentId+'&&seasonId='+seasonId+'&&episodeId='+x._id+'&&type=episode_playback&&path='+data)
    }
}
const slide = (shift) => {
    scrl.current.scrollLeft += shift;
  }; 
  return (
    <>
    <section className='content-details'>
    <img src={static_url+ persons.landscapePosterId} id="back"></img>
    <div className='container'>
    <div className='row'>
        <div className='col-md-12'>
            <img src={Play} onClick={()=> assign(persons.trailerFileUrl ,persons.title)} className="big_play_btn"/>
            <img src={static_url + persons.landscapePosterId} className="main-content"/>

            <div className='bottom_img'>
                    <span>Rated</span>
                    <span>{persons.ageGroup}</span>
            </div>

            {flag ? 
            <div className="season_outer">
                <div className='movable'>
                <img src={prev} onClick={()=>slide(-280)}/>
                <img src={next} onClick={()=>slide(+280)}/>
                </div>
            <div className='season' ref={scrl}>
            {child.episodes.map((x,index) => { 
            return ( 
            <div className='vooviepisode' key={index}>
            <img src={static_url+x.landscapePosterId} key={index} onClick={()=> handleepisode(x)} id="epi"/>
            <div className='inner_episode'>{x.name}</div>
            </div>
            )
            })
            
            } 
            </div>
            {/*<span className='all'>
                    <img src={prev} onClick={() => slide(-280)}/>
                    <img src={next} onClick={() => slide(+280)}/>
        </span>*/}
            </div>
            : ''
            }

            <div className='row'>
                <div className='col-md-9'>
                    <h2>{persons.title}</h2>
                    <br/>
                    <br/>
                    <ul>
                        { persons.tags.map((x,index)=>{
                                return(
                                    <li key={index}>{x}</li>
                                )
                        })}
                    </ul>
                    {/*<button className='btn qwer' onClick={()=> assign(persons.trailerFileUrl ,persons.title)}><i className="fa fa-play-circle p-1"></i> Play Trailer</button>
                    <button className='btn qwer'><i className="fa fa-plus-circle p-1"></i> Add Watch List</button>*/}
                </div>
                
            </div>
            <hr/>
        
            <div className='row'>
                <div className='col-md-12'>
                <h2>Storyline</h2>
                <p>{description}</p>

                <h2>Cast</h2>
                <p>{casts.map((c,index) => { 
                    if( casts.lastIndexOf(c) !== casts.length-1 ){
                        return (
                            <span key={index}>{ c + ' , '}</span>
                            ) 
                    }else{
                        return (
                            <span key={index}>{' '+ c}</span>
                            )
                    }
                   })}
                   </p>
                <br/> 
                <h2>Director</h2>
                <p>{persons.director}</p>
                </div>
            </div>
        </div>
       
    </div>

    
    </div>
    </section>
    </>
  )
}

export default Contentdetails