import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Loginone from './components/Loginone';
import Header from './components/Header';
import Registerone from './components/Registerone';
import Protected from './services/Protected';
import Redirect from './services/Redirect';
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Contentdetails from './components/Contentdetails';
import Listpackage from './components/Listpackage';
import Local from './services/Local';
import Updateprofile from './components/Updateprofile';
import Privacypolicy from './components/Details/Privacypolicy';
import Termsandcondition from './components/Details/Termsandcondition';
import Refundpolicy from './components/Details/Refundpolicy';
import Aboutus from './components/Details/Aboutus';
import Contactus from './components/Details/Contactus';
import Listpaymentgateway from './components/Gateway/Listpaymentgateway';
import Changepassword from './components/Userconfig/Changepassword';
import Getuserdetails from './components/Userconfig/Getuserdetails';
import Upcomingcontentdetails from './components/Upcomingcontentdeatils';
import Success from './components/Gateway/Success'
import Success_paytm from './components/Gateway/Success_paytm'
import ReactPlay from './components/Player/Reactplay'
import Activateemail from './components/Userconfig/Activateemail'
import Forgetpassword from './components/Userconfig/Forgetpassword';
import Upcomingcontents from './components/Upcomingcontents';
import Weboslogin from './components/Userconfig/Weboslogin';
import Grievances from './components/Details/Grievances';
import Adds from './components/Advertise/Adds';
import Notfound from './components/Notfound';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserAuthContextProvider>
  <Router>
    <Routes>
      {/*root routing */}
      <Route path="*" element={<Notfound/>}/> 
      <Route path="/" element={<Local Protect={App}/>}/>
      <Route path="/login" element={<Redirect Protect={Loginone}/>}/>
      <Route path="/login/:queries" element={<Redirect Protect={Loginone}/>}/>
      <Route path="/qr/verify/:token/:socketId" element={<Local Protect={Weboslogin}/>}/>
      <Route path="/register" element={<Redirect Protect={Registerone}/>}/>
      <Route path="/my-profile/:userId" element={<Protected Protect={Getuserdetails}/>}/>
      <Route path="/content/details/:contentId" element={<Local Protect={Contentdetails}/>}/>
      <Route path="/upcoming/contents" element={<Local Protect={Upcomingcontents}/>}/>
      <Route path="/upcoming/content/details/:contentId" element={<Local Protect={Upcomingcontentdetails}/>}/>
      <Route path="/subscriptions/plans/" element={<Local Protect={Listpackage}/>}/>
      <Route path="/privacypolicy" element={<Local Protect={Privacypolicy}/>}/>
      <Route path="/grievances-redressal" element={<Local Protect={Grievances}/>}/>
      <Route path="/termsconditions" element={<Local Protect={Termsandcondition}/>}/>
      <Route path ="/Rfpolicy" element={<Local Protect={Refundpolicy}/>}/>
      <Route path="/aboutus" element={<Local Protect={Aboutus}/>}/>
      <Route path="/contactus" element={<Local Protect={Contactus}/>}/>
      <Route path="/my-profile/update/:userId" element={<Protected Protect={Updateprofile}/>}/>
      <Route path="/my-profile/update/resetpassword/:userId" element={<Local Protect={Changepassword}/>}/>
      <Route path="/subscriptions/payments/modes/active/:packageId" element={<Protected Protect={Listpaymentgateway}/>}/>
      <Route path="/subscriptions/payments/success/:paymentId/:orderId" element={<Protected Protect={Success}/>}/>
      <Route path="/subscriptions/:payments" element={<Protected Protect={Success_paytm}/>}/>
      <Route path="/trailer/play/:title/:id" element={<Local Protect={ReactPlay}/>}/>   
      <Route path="/upcoming/trailer/play/:title/:id" element={<Local Protect={ReactPlay}/>}/>   
      <Route path="/episode/play/:title/:id" element={<Protected Protect={ReactPlay}/>}/> 
      <Route path="activateemail/:activateemail" element={<Local Protect={Activateemail}/>}/>   
      <Route path="/forgetpassword" element={<Local Protect={Forgetpassword}/>}/>    
      <Route path="/chnagepassword" element={<Local Protect={Changepassword}/>}/>    
      <Route path="/play/:id" element={<Local Protect={ReactPlay}/>}/>   
      <Route path="/advertisement" element={<Adds/>}/>   
    </Routes>
  </Router>
  </UserAuthContextProvider>
)

export const loginrouter =()=>{
  return(
    <>
    <Header/>
    <Loginone/>
    </>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
