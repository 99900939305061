import React from 'react'
import footer from '../assets/image/logo.png';
import app1 from "../assets/image/download_app_1.png";
import app2 from "../assets/image/download_app_2.png";
import '../App.css';
const Footer = () => {
  return (
    <footer>
    <div className="container">
        <div className="row footer_area p-4">
            <div className="col-md-3">
                <a href="/" className="footer_logo"><img src={footer} alt=""/></a>
                <div className="footer_download">
                    <h3>Download Our App</h3>
                    <a href="https://play.google.com/store/apps/details?id=com.rabbit.android.pro.release"><img src={app1} alt=""/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.rabbit.android.pro.release" className='mt-2'><img src={app2} alt=""/></a>
                </div>
            </div>
            <div className="col-md-3">
                <h2>Quick Links</h2>
                <ul className="quick_link">
                    <li className="active"><a href="/">Home</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                    <li><a href="/aboutUs">About Us</a></li>
                    <li><a href="/subscriptions/plans/">Subscription</a></li>
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/termsconditions">Terms  Conditions</a></li>
                    <li><a href="/rfpolicy">Refund Policy</a></li>
                    <li><a href="/grievances-redressal">Grievances-Redressal</a></li>
                </ul>
            </div>
            <div className="col-md-6">
                <h2>Get In Touch</h2>
                <div className="newsletter_form">
                    <input type="text" placeholder="Enter Your Email Address" name=""/>
                    <input type="submit" value="SUBSCRIBE" name=""/>
                </div>
               {/* <ul className="social_icon">
                    <li><a href="/"><i className="fa fa-facebook" aria-hidden="true"></i>asdsdf</a></li>
                    <li><a href="/"><i className="fa fa-twitter" aria-hidden="true"></i>adff</a></li>
                    <li><a href="/"><i className="fa fa-youtube-play" aria-hidden="true"></i>dsaffd</a></li>
                    <li><a href="/"><i className="fa fa-instagram" aria-hidden="true"></i>af</a></li>
  </ul>*/}
            </div>
        </div>
        <div className="last_footer">
            <p>© 2020 All Rights Reserved to <span>Rabbit</span>. This website is owned by <span>Rabbit</span>. -v 1.0.2</p>
        </div>
    </div>
</footer>
  )
}

export default Footer