import React, { useEffect, useState } from 'react'
import '../App.css';
import logo from '../assets/image/logo.png';
import subscribe from '../assets/image/subscribe_icon.png';
import search1 from "../assets/image/search_icon.png";
import {useNavigate} from 'react-router-dom';
import menubar from "../assets/image/mobile_menu_icon.png";
import closemenu from '../assets/image/mobile_menu_close_icon.png';
import { useUserAuth } from '../context/UserAuthContext';
import { auth } from "../firebase";

const static_url = process.env.REACT_APP_IMAGE_CDN;
const Header = (props) => {
    const [state_google,setgoogle]=useState(false)
    const [collection ,setcollection]=useState([])
    //const [categories,setcategories]=useState([])
    const [menu_state,setmenustate]=useState(true)
    const [input,setinput]=useState('')
    const [userid,setuserid]=useState('')
    const us =localStorage.getItem('user')

    //importing function 
    const { allContents} = useUserAuth();

    //for widgets
    const closemenubar=()=>{
        setmenustate(!menu_state)
    }

    useEffect(()=>{ 
        //auth.onAuthStateChanged(userAuth => {})
        //setcategories(allCategories)    
        const id = localStorage.getItem('id')  
        if(!id){
            
        }else{
            setuserid(id)
        }
    },[])

    const filter_result=(data, key)=>{
        const title = data.filter(x=>x.test.title.toLowerCase().includes(key.toLowerCase()))
        if(key.length <= 0){
            setcollection([])
        }else{
            setcollection(title)
        }
        
    }
    const handlesearch=(e)=>{
        const val = e.target.value
        setinput(val)
        filter_result(allContents,val)
    }
   
const [search_view,setsearch]=useState(false)

return (
    <div className='container'>
        <header>
        <a href="/" className="logo"><img src={logo} alt=""/></a>
        <div className="right_nav">
       
            <div className='mobile_primary'>
            <img onClick={()=> closemenubar()}  src={menubar}/>
                <ul  className = {menu_state ? 'deactive ': 'active'}>
                <img onClick={()=> closemenubar()} src={closemenu}></img>
                    <li><a href="/">Home</a></li>
                    <li><a href="#" onClick={()=> {setsearch(true);closemenubar()}}>Search</a></li>
                    <li><a href="/upcoming/contents">Upcoming</a></li>
                    {!us ? <li><a href="/subscriptions/plans/">Subscription</a></li>:''}
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/refundpolicy">Refund Policy</a></li>
                    <li><a href="/Contactus">Contact Us</a></li>
                </ul>
            </div>
            
            
            <div className={!menu_state ? "header_btn deactive":"header_btn"}>
            { props.state || state_google ? 
                    <ul>
                    <li className='sun'>
                    <a href={`/my-profile/data?id=${userid}`} ><i className="fa fa-user"></i> Account</a>
                    </li>
                    </ul>
                : 
                <ul>
                    <li><a href = "/register">Register</a></li>
                    <li><a href = "/login">Login</a></li>
                </ul>
            }
            </div>
            {!us ? <a href="/subscriptions/plans/" className="click_to_subsc"><span ><img className="pt-2" src={subscribe}
                        alt=""/></span>Subscription</a>:''}
            
            <div className="header_search">
                <div className="toggle_search" onClick={()=> setsearch(true)}>
                    <span><img src={search1} alt=""/></span>Search
                </div>
                <div className={search_view ? 'toggle_search_open':'toggle_search_open open'} >
                    <div className="container">
                        <div className="toggle_search_open_area">
                            <div className="toggle_close" onClick={()=> setsearch(!search_view)} ></div>
                            <input type="text" placeholder="Search" value={input} onChange={handlesearch}  name=""/>
                            <input type="submit" name=""/>
                        </div>
                         {/*for search result */}
                         <section className="product">
                            <div className="container">
                                <div className="product_category">
                                    <div className="product_item_list">
                                    {collection.map( (y,index) => {
                                        if(y.test.status === 'active'){
                                            return(
                                                <a  key={index} href={'/content/details/'+`${y.test._id}`} className="product_item">
                                                    <div className="product_item_image">
                                                        <img src={static_url + y.test.portraitPosterId} alt=""/>
                                                        <div className="product_item_image_overlay.test">
                                                            <div className="play_icon"></div>
                                                        </div>
                                                    </div>
                                                    <p>{y.test.title}</p>
                                                </a>
                                                )}
                                    })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> 
            </div>
            <a href="/upcoming/contents" className="click_to_subsc"><i className="fa fa-film"></i> Upcoming</a>
            <a href="/" className="click_to_subsc"><i className="fa fa-home"></i> Home</a>
        </div>

        </header>
    </div>
  )
}
export default Header