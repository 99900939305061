import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const apiKEY=process.env.REACT_APP_API_KEY;
const profile=process.env.REACT_APP_CDN_PROFILE;
var token =localStorage.getItem('access-token') ;
var userId = localStorage.getItem('id') 
var string ;
if(userId && token ){
    string = btoa(userId+':'+token) 
}
const Access = axios.create({
    baseURL: baseURL,
    headers:{
    "Content-Type": "application/json",
    "signature":"scqbKKFpKhGSBISG8ZIO8GQs1rU",
    "accesstoken": token
    }
});
const Profile = axios.create({
    baseURL: baseURL,
    responseType: "arraybuffer",
    headers:{
    "Content-Type": "application/json",
    "signature":"scqbKKFpKhGSBISG8ZIO8GQs1rU",
    "accesstoken": token
    }
});
const Updateinstance =axios.create({
    baseURL: baseURL,
    headers:{
    "userId":userId,
    "signature":"scqbKKFpKhGSBISG8ZIO8GQs1rU",
    "accesstoken": token
    }
});
const Accessinstance =axios.create({
    baseURL: profile,
    headers:{
    'Authorization': 'Basic ' + string
    }
});

const Episode_playback = axios.create({
    baseURL:baseURL,
    headers:{
        "userId":userId,
        "drmapiKey":"dan8fKLn1nd2nnRap2pn5w==",
        "signature":"scqbKKFpKhGSBISG8ZIO8GQs1rU",
        "accesstoken": token,
        "versioncode":"1.0"
    }
})
export  {Access,Profile,Updateinstance,Accessinstance,Episode_playback};
